import {
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useState,
} from 'react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AgxHeader,
  AgxRow,
  AgxColumn,
  Images,
  AgxButton,
  AgxCaption,
  AgxBodyText,
  AgxBadge,
  AgxBadgeType,
} from '@urbanx/agx-ui-components'
import {
  useAgentsInAgency,
  useSelectedCampaign,
  useSelectedContract,
} from 'hooks'
import PlaceHolderImage from 'components/placeholder-image/PlaceholderImage'
import { MenuTitles, Pages } from 'constants/menu'
import { cleanTwoLineAddress } from 'helpers/address'
import placeHolderImage from 'assets/images/placeholder-property.png'
import './pageheader.scss'

type PageHeaderHandle = {
  closeMenuIfOpen: () => void
}
export interface PageHeaderProps {
  agencyName: string
  agentName: string
  isDashboard?: boolean
  isStaff?: boolean
  isCampaignsSection?: boolean
  isError?: boolean
  agentImgUrl?: string
  agencyLogoUrl?: string
  currentPageTitle?: string
  agencyBackgroundColor?: string
}

const PageHeader: ForwardRefRenderFunction<
  PageHeaderHandle,
  PageHeaderProps
> = (props, ref) => {
  const {
    agencyName,
    agentName,
    currentPageTitle,
    isDashboard,
    isError,
    agentImgUrl,
    agencyLogoUrl,
    isCampaignsSection,
    agencyBackgroundColor,
  } = props

  const [showMenu, setShowMenu] = useState(false)
  const imgUrl = isDashboard ? agentImgUrl : agencyLogoUrl
  const navigate = useNavigate()
  const { agencyId, campaignId } = useParams()
  const { campaign: selectedCampaign } = useSelectedCampaign()
  const { contract: selectedContract } = useSelectedContract()
  const { agents } = useAgentsInAgency()

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(
    selectedCampaign?.address
  )

  useImperativeHandle(ref, () => ({
    closeMenuIfOpen() {
      if (showMenu) setShowMenu(false)
    },
  }))

  const generateBackButtonBreadCrumbs = () => {
    if (selectedContract) {
      return (
        <AgxRow mediumGap>
          <AgxCaption>{agencyName}</AgxCaption>
          <AgxCaption colour="neutral-grey-700">{`/ ${MenuTitles.CAMPAIGNS} `}</AgxCaption>
          <AgxCaption colour="neutral-grey-700">{`/ ${addressLineOne}`}</AgxCaption>
          <AgxCaption colour="neutral-grey-700">{`/ ${selectedContract?.contractName}`}</AgxCaption>
        </AgxRow>
      )
    }
    if (campaignId) {
      return (
        <AgxRow mediumGap>
          <AgxCaption>{agencyName}</AgxCaption>
          <AgxCaption colour="neutral-grey-700">{`/ ${MenuTitles.CAMPAIGNS} `}</AgxCaption>
          <AgxCaption colour="neutral-grey-700">{`/ ${addressLineOne}`}</AgxCaption>
        </AgxRow>
      )
    }
    if (agencyId) {
      return (
        <AgxRow mediumGap>
          <AgxCaption>Agencies</AgxCaption>
        </AgxRow>
      )
    }
  }

  const generatePageTitle = () => {
    if (!isCampaignsSection) {
      return (
        <AgxColumn extraClasses="headerTitle" data-testid="headerTitle">
          <AgxHeader size={2}>
            {isDashboard ? `Hey ${agentName}` : agencyName}{' '}
            {currentPageTitle && (
              <span className="breadcrumb__header">{` / ${currentPageTitle}`}</span>
            )}
          </AgxHeader>
        </AgxColumn>
      )
    } else {
      if (selectedContract) {
        return (
          <AgxColumn fill>
            <AgxHeader size={2}>{selectedContract.contractName}</AgxHeader>
            <AgxBodyText large>
              {addressLineOne ? (
                <>
                  {addressLineOne}, {addressLineTwo}
                </>
              ) : (
                <>Address Unidentified</>
              )}
            </AgxBodyText>
          </AgxColumn>
        )
      } else {
        return (
          <AgxColumn fill>
            <AgxHeader size={2}>
              {addressLineOne ? (
                <>
                  {addressLineOne}, {addressLineTwo}
                </>
              ) : (
                <>Address Unidentified</>
              )}
            </AgxHeader>
          </AgxColumn>
        )
      }
    }
  }

  const generateListingAgents = () => {
    const listingAgents = []

    if (isCampaignsSection || !!selectedContract) {
      if (selectedCampaign?.leadAgentId) {
        const leadAgent = agents?.find(
          (agent) => agent.id === selectedCampaign.leadAgentId
        )

        if (leadAgent) {
          listingAgents.push(
            <AgxRow extraClasses="listingAgents">
              {leadAgent.photoUrl && (
                <img className="listingAgentImage" src={leadAgent.photoUrl} />
              )}
              <AgxBodyText small>
                {leadAgent.name?.firstName} {leadAgent.name?.lastName}
              </AgxBodyText>
            </AgxRow>
          )
        }
      }

      if (selectedCampaign?.secondAgentId) {
        const secondAgent = agents?.find(
          (agent) => agent.id === selectedCampaign.secondAgentId
        )

        if (secondAgent) {
          listingAgents.push(
            <AgxRow extraClasses="listingAgents">
              {secondAgent.photoUrl && (
                <img className="listingAgentImage" src={secondAgent.photoUrl} />
              )}
              <AgxBodyText small>
                {secondAgent.name?.firstName} {secondAgent.name?.lastName}
              </AgxBodyText>
            </AgxRow>
          )
        }
      }
    }

    return <AgxRow fill>{listingAgents}</AgxRow>
  }

  const generateListingBadges = () => {
    const listingBadges = []

    if (selectedCampaign?.launchDate) {
      dayjs.extend(advancedFormat)
      const launchDate = dayjs(selectedCampaign.launchDate).format('Do MMM')
      listingBadges.push(
        <AgxBadge type={AgxBadgeType.Needed} text={`Due on ${launchDate}`} />
      )
    }

    if (selectedCampaign?.blankContractIsLive) {
      listingBadges.push(
        <AgxBadge type={AgxBadgeType.Success} text="Contract Live" />
      )
    }

    return (
      <AgxRow mediumGap fill>
        {listingBadges}
      </AgxRow>
    )
  }

  const onBackClick = () => {
    if (selectedContract) {
      navigate(
        `/${agencyId}/${Pages.CAMPAIGNS}/${campaignId}/${Pages.CONTRACTS}`
      )
    } else if (campaignId) {
      navigate(`/${agencyId}/${Pages.CAMPAIGNS}`)
    } else {
      navigate('/')
    }
  }

  if (isError) {
    return (
      <div className="headerContaineronError">
        <section className="titleBar">
          <div onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <Images.UrbanXWhite />
          </div>
          {imgUrl ? (
            <img className="titleLogo" src={imgUrl} />
          ) : (
            <PlaceHolderImage size={32} title={agentName} />
          )}
        </section>
      </div>
    )
  }

  return (
    <AgxColumn extraClasses={'headerRow'}>
      {(agencyId || campaignId) && (
        <AgxRow>
          <AgxButton
            leftIcon={<Images.ArrowBackOutline />}
            naked
            text={generateBackButtonBreadCrumbs()}
            onClick={() => onBackClick()}
          />
        </AgxRow>
      )}
      {!isCampaignsSection && (
        <section>
          <AgxRow>
            {agencyName === 'UrbanX' ? (
              <AgxColumn extraClasses="paddingRight2">
                <Images.RealEstateLogo />
              </AgxColumn>
            ) : imgUrl ? (
              <div className="imageContainer">
                <img
                  className="agencyLogo"
                  src={imgUrl}
                  style={{ background: agencyBackgroundColor }}
                />
              </div>
            ) : (
              <PlaceHolderImage
                size={56}
                title={isDashboard ? agentName : agencyName}
              />
            )}
            {generatePageTitle()}
          </AgxRow>
        </section>
      )}
      {isCampaignsSection && (
        <section>
          <AgxRow fill extraClasses="contractCard">
            <AgxRow extraClasses="propertyImageContainer">
              <img
                className="propertyImage"
                src={
                  selectedCampaign?.propertyImageUrls?.large ?? placeHolderImage
                }
              />
            </AgxRow>
            <AgxRow extraClasses="headerListing" fill>
              <div className="headerListingAddress">{generatePageTitle()}</div>
              <div className="headerListingAgents">
                {generateListingAgents()}
              </div>
              <div className="headerListingBadges">
                {generateListingBadges()}
              </div>
            </AgxRow>
          </AgxRow>
        </section>
      )}
    </AgxColumn>
  )
}

export default forwardRef(PageHeader)
