import { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxRow,
  AustralianState,
  cleanFullAddress,
  formatCurrency,
  Images,
  AgxBadge,
  AgxBadgeType,
} from '@urbanx/agx-ui-components'
import { useNavigate } from 'react-router-dom'
import { MenuTitles } from 'constants/menu'
import { UserContext } from 'contexts'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { ContentLayout, PageLayout } from 'layout'
import { useSelectedAgency, useSelectedCampaign } from 'hooks'
import { GetContractsForCampaign } from 'services'
import { Contract } from 'types/campaigns'
import './Contracts.scss'

const ValidQldStages = ['Accepted', 'Unconditional', 'Terminated', 'Settled']

const ValidNswStages = [
  'Sales Advice Sent',
  'Contract Requested',
  'Accepted',
  'Unconditional',
  'Terminated',
  'Settled',
]

const GetStatusBadgeType = (stage?: string) => {
  switch (stage) {
    case 'Accepted':
      return AgxBadgeType.Success
    case 'Unconditional':
      return AgxBadgeType.Waiting
    case 'Terminated':
      return AgxBadgeType.Action
    case 'Settled':
      return AgxBadgeType.Needed
    case 'Sales Advice Sent':
      return AgxBadgeType.Action
    case 'Contract Requested':
      return AgxBadgeType.OnGoing
    default:
      return AgxBadgeType.Primary
  }
}

const Contracts = () => {
  const [, getAuthToken] = useAzureAuth()
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const { agency } = useSelectedAgency()
  const { campaign } = useSelectedCampaign()

  const contractsQueryKey = ['all-contracts', campaign?.campaignId]

  const { data: contracts, isLoading: contractsIsLoading } = useQuery<
    Contract[] | undefined
  >({
    queryKey: contractsQueryKey,
    queryFn: () => {
      return GetContractsForCampaign(campaign?.campaignId, getAuthToken)
    },
    enabled: !!campaign?.campaignId,
  })

  const filteredContracts = useMemo(() => {
    if (!contracts) return []

    if (campaign?.state === AustralianState.NSW)
      return contracts.filter((c) => ValidNswStages.includes(c.stage ?? ''))
    else if (campaign?.state === AustralianState.QLD)
      return contracts.filter((c) => ValidQldStages.includes(c.stage ?? ''))
    else return []
  }, [campaign?.state, contracts])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={agency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(campaign?.address)}`}
      isCampaignsSection={true}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.CONTRACTS}
        isCampaigns={true}
      >
        <AgxColumn extraClasses="campaignContracts" veryLargeGap>
          <AgxRow spaceBetween>
            <AgxRow centered>
              <AgxHeader size={5}>All Contracts</AgxHeader>
            </AgxRow>
            <AgxRow centered>
              <AgxButton hollow medium disabled text="New Contract" />
            </AgxRow>
          </AgxRow>
          <table className="campaignContractTable">
            <tbody>
              <tr>
                <th align="left" className="column-name">
                  <AgxBodyText small>Contract</AgxBodyText>
                </th>
                <th align="left" className="column-name">
                  <AgxBodyText small>Sale Price</AgxBodyText>
                </th>
                <th align="left" className="column-name">
                  <AgxBodyText small>Initial Deposit</AgxBodyText>
                </th>
                <th align="left" className="column-name">
                  <AgxBodyText small>Balance Deposit</AgxBodyText>
                </th>
                <th align="left" className="column-name">
                  <AgxBodyText small>Status</AgxBodyText>
                </th>
              </tr>
              {!!filteredContracts &&
                filteredContracts.length > 0 &&
                filteredContracts.map((contract) => (
                  <tr
                    key={contract.contractId}
                    className="contractDetailRow"
                    onClick={() => {
                      navigate(`${contract.contractId}/files`)
                    }}
                  >
                    <td>
                      <AgxRow mediumGap centered>
                        <Images.SparkleSmall />
                        <AgxHeader size={4}>{contract.contractNames}</AgxHeader>
                      </AgxRow>
                    </td>
                    <td>
                      <AgxColumn fill smallGap>
                        {contract.salePrice
                          ? formatCurrency(contract.salePrice, 0)
                          : '$--'}
                      </AgxColumn>
                    </td>
                    <td>
                      <AgxColumn fill smallGap>
                        {contract.initialDeposit
                          ? formatCurrency(contract.initialDeposit)
                          : '$--'}
                      </AgxColumn>
                    </td>
                    <td>
                      <AgxColumn fill smallGap>
                        {contract.balanceDeposit
                          ? formatCurrency(contract.balanceDeposit)
                          : '$--'}
                      </AgxColumn>
                    </td>
                    <td>
                      <AgxBadge
                        text={contract.stage ?? ''}
                        type={GetStatusBadgeType(contract.stage)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {(!filteredContracts || filteredContracts.length === 0) && (
            <AgxColumn
              fill
              centered
              centerJustified
              extraClasses="contractDetailNoContracts"
            >
              <AgxBodyText>
                {contractsIsLoading
                  ? 'Loading...'
                  : 'No Contracts have been submitted for this campaign'}
              </AgxBodyText>
            </AgxColumn>
          )}
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default Contracts
