import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { FeatureToggles } from '@urbanx/agx-ui-components'
import { GetFeatureToggles } from 'services'
import { useAzureAuth } from './useAzureAuth'

const useFeatureToggles = (): {
  featureToggles?: FeatureToggles
  isLoading: boolean
  isFetched: boolean
} => {
  const { campaignId } = useParams()
  const [, getAuthToken] = useAzureAuth()
  const featureTogglesQueryKey = ['feature-toggles', campaignId]

  const {
    data: featureToggles,
    isLoading,
    isFetched,
  } = useQuery<FeatureToggles | undefined>({
    queryKey: featureTogglesQueryKey,
    queryFn: (ctx) =>
      GetFeatureToggles(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!campaignId,
  })

  return {
    featureToggles,
    isLoading,
    isFetched,
  }
}

export default useFeatureToggles
