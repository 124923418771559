import {
  use,
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useCallback,
} from 'react'
import {
  AgxRow,
  AgxColumn,
  AgxButton,
  AgxBodyText,
  AgxLabel,
  AgxSwitch,
  FormType,
  AgxToast,
  filterPageHideCondition,
  filterDisplayElementHideCondition,
  DocumentTypes,
  CampaignStage,
  FormPage,
  useDeepMemo,
  AustralianState,
  RenderAnimation,
  RenderAnimationType,
  AgxMultiOptionButton,
  DocumentTypesMap,
  AgxIconCircularGreenTick,
  AgxIconCircularIncomplete,
  AgxIconCircularWarning,
  CampaignDetailModel,
  cleanFullAddress,
  UploadedDocument,
} from '@urbanx/agx-ui-components'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash.isequal'
import slugify from 'slugify'
import dayjs from 'dayjs'
import { ContentLayout, PageLayout } from 'layout'
import { NotificationContext, UserContext } from 'contexts'
import { MenuTitles } from 'constants/menu'
import { useAzureAuth } from 'hooks/useAzureAuth'
import elementRenderer from 'utils/elementRenderer'
import { FormValue, FormValueType } from 'types/formConfig'
import { AgxToastState } from 'types/commonTypes'
import ErrorModal from 'helpers/ErrorModal'
import { NotificationType, PdfProcessedEvent } from 'types/notification'
import { FormPrompt } from 'components/FormPrompt'
import { getEnumValue } from 'helpers/enumHelper'
import {
  useFeatureToggles,
  useFormConfig,
  useSelectedAgency,
  useSelectedCampaign,
} from 'hooks'
import { openFileInNewTab } from 'utils/openFileInNewTab'
import blankContractReducer, { ActionType } from './blankContractReducer'
import SetBlankContractConfirmModal from './SetBlankContractConfirmModal'
import './ManageCampaign.scss'

interface Props {
  formType: FormType
}

// Number of seconds before alerting the user the PDF generation timed out
const PDF_GENERATION_TIMEOUT = 30

const ManageCampaign = (props: Props) => {
  const { formType } = props
  const { campaignId } = useParams()
  const { featureToggles } = useFeatureToggles()
  const { agency: selectedAgency } = useSelectedAgency()
  const { campaign } = useSelectedCampaign()
  const {
    formConfig,
    isLoading: isLoadingFormConfig,
    saveForm,
    submitForm,
  } = useFormConfig(formType)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const stickyHeaderRow = useRef<HTMLDivElement>(null)
  const stickyLeftMenu = useRef<HTMLDivElement>(null)
  const panelContainer = useRef<HTMLDivElement>(null)
  const user = use(UserContext)
  const notifications = use(NotificationContext)
  const [, getAuthToken] = useAzureAuth()
  const [validate, setValidate] = useState(false)
  const [errorContainerNames, setErrorContainerNames] = useState<string[]>([])
  const [notifyAgent, setNotifyAgent] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [isGeneratingBlankContract, setIsGeneratingBlankContract] =
    useState<boolean>(false)
  const [submitFormErrors, setSubmitFormErrors] = useState<string[]>([])
  const stickyHeaderOffsetTop = 254
  const [blankContractState, dispatch] = useReducer(blankContractReducer, {
    initialized: false,
  })
  const [, setPdfGenerationStartTime] = useState<number | undefined>(undefined)
  const formValues = blankContractState as FormValue

  const showQldInfo = campaign?.state === AustralianState.QLD
  const campaignIsValid = useMemo<boolean>(
    () =>
      campaign?.stage
        ? [
            CampaignStage.AgreementSignedByAllParties,
            CampaignStage.ListingCurrent,
            CampaignStage.ListingUnderContract,
          ].includes(getEnumValue(CampaignStage, campaign.stage))
        : false,
    [campaign?.stage]
  )

  // Prepopulate form values with existing data
  useEffect(() => {
    if (!isLoadingFormConfig && !!campaign?.campaignId && !!formConfig) {
      dispatch({
        type: ActionType.INIT,
        payload: formConfig.existingData,
      })
    }
  }, [isLoadingFormConfig, campaign?.campaignId, formConfig?.existingData])

  useEffect(() => {
    if (blankContractState.initialized) {
      setTimeout(() => {
        setIsInitialRender(false)
      }, 500)
    }
  }, [blankContractState.initialized])

  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  const validatePage = async () => {
    setValidate(true)
    await delay(100)

    const errorElements = document.getElementsByClassName('error')
    const errorContainers: string[] = []
    pages.forEach((page) => {
      const container = document.getElementById(slugify(page.name))
      if (container) {
        const errorInContainers = container.getElementsByClassName('error')
        if (errorInContainers && errorInContainers.length > 0) {
          errorContainers.push(page.name)
        }
        const actionAlertsInContainers = container.getElementsByClassName(
          'actionAlertContainer'
        )
        const infoAlertsInContainers = container.getElementsByClassName(
          'informationAlertContainer'
        )
        if (
          (actionAlertsInContainers && actionAlertsInContainers.length > 0) ||
          (infoAlertsInContainers && infoAlertsInContainers.length > 0)
        ) {
          errorContainers.push(page.name)
        }
      }
    })
    setErrorContainerNames(errorContainers)
    return errorElements && errorElements.length > 0 ? false : true
  }

  const toggleLiveBlankContract = async (isSwitchLive: boolean) => {
    if (!campaignIsValid || isLoadingFormConfig) return
    if (isSwitchLive) {
      setOpenConfirmationModal(true)
    } else {
      onSubmit()
    }
  }

  const onSaveFormValues = async () => {
    if (!campaign?.state || isLoadingFormConfig) return

    if (campaignIsValid) {
      if (showQldInfo) {
        setIsGeneratingBlankContract(true)
        setPdfGenerationStartTime(Date.now())
      }

      saveForm(formValues, [], {
        onSuccess: (result) => {
          setIsFormDirty(false)
          setNotifyAgent(false)

          if (result?.blankContractIsLive) {
            updateToastState({
              color: 'success',
              message: featureToggles?.submitListingToRex
                ? 'Blank contract is saved live, and sent to REX'
                : 'Blank Contract is saved live',
              open: true,
            })
          } else {
            updateToastState({
              color: 'success',
              message: 'Blank contract saved and is offline',
              open: true,
            })
          }

          validatePage()
        },
        onError: () => {
          setIsGeneratingBlankContract(false)
          setPdfGenerationStartTime(undefined)
          updateToastState({
            color: 'error',
            message: 'Error saving campaign',
            open: true,
          })
        },
      })
    } else {
      updateToastState({
        color: 'error',
        message: 'Campaign is not ready for blank contract generation',
        open: true,
      })
    }
  }

  const onSubmit = async () => {
    if (!campaign?.state || !campaignIsValid || isLoadingFormConfig) return

    await validatePage()

    if (showQldInfo) {
      setIsGeneratingBlankContract(true)
      setPdfGenerationStartTime(Date.now())
    }

    submitForm(formValues, notifyAgent, {
      onSuccess: async (data) => {
        if (data?.errors?.length ?? 0 > 0) {
          setSubmitFormErrors(data?.errors ?? [])
          setOpenErrorModal(true)
          setNotifyAgent(false)
          setIsGeneratingBlankContract(false)
          setPdfGenerationStartTime(undefined)
        } else {
          setIsFormDirty(false)
        }

        if (data?.blankContractIsLive) {
          updateToastState({
            color: 'success',
            message: featureToggles?.submitListingToRex
              ? 'Blank contract is saved live, and sent to REX'
              : 'Blank Contract is saved live',
            open: true,
          })
        } else {
          updateToastState({
            color: 'success',
            message: 'Blank contract is offline',
            open: true,
          })
        }
      },
      onError: () => {
        setIsGeneratingBlankContract(false)
        setPdfGenerationStartTime(undefined)
        updateToastState({
          color: 'error',
          message: 'Error generating blank contract',
          open: true,
        })
      },
    })
  }

  const onChange = (change: {
    id: string
    value: FormValueType[keyof FormValueType]
  }) => {
    const { id, value } = change

    if (
      id === 'CustomSpecialConditions' ||
      (formValues && !isEqual(formValues[id], change.value))
    ) {
      if (!isInitialRender) {
        setIsFormDirty(true)
      }
      dispatch({ type: ActionType.UPDATE, payload: value, key: id })
    }
  }

  const pages = useDeepMemo<FormPage[]>(() => {
    return filterPageHideCondition(formConfig?.pages ?? [], formValues)
  }, [campaign?.campaignId, formValues, formConfig?.pages])

  const handleScroll = useCallback(() => {
    if (stickyHeaderRow.current) {
      if (window.scrollY > stickyHeaderOffsetTop) {
        ;(stickyHeaderRow.current as HTMLDivElement).classList.add('sticky')

        if (stickyLeftMenu.current)
          (stickyLeftMenu.current as HTMLDivElement).classList.add('stickyMenu')
        if (panelContainer.current) {
          ;(panelContainer.current as HTMLDivElement).classList.add(
            'addMarginToPanelContainer'
          )
        }
      } else {
        ;(stickyHeaderRow.current as HTMLDivElement).classList.remove('sticky')

        if (stickyLeftMenu.current)
          (stickyLeftMenu.current as HTMLDivElement).classList.remove(
            'stickyMenu'
          )

        if (panelContainer.current) {
          ;(panelContainer.current as HTMLDivElement).classList.remove(
            'addMarginToPanelContainer'
          )
        }
      }
    }
  }, [stickyHeaderRow, stickyLeftMenu, panelContainer])

  useEffect(() => {
    if (showQldInfo) {
      document.addEventListener('scroll', handleScroll, false)
    }

    return () => {
      if (showQldInfo) {
        document.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [showQldInfo, handleScroll])

  const pdfProcessed = (event: PdfProcessedEvent) => {
    if (event.campaignId !== campaignId) return

    if (
      event.uploadedDocument.documentType === DocumentTypes.BlankSalesContract
    ) {
      setPdfGenerationStartTime(undefined)
      setIsGeneratingBlankContract(false)
      setIsFormDirty(false)
      setNotifyAgent(false)
      updateToastState({
        color: 'success',
        message: 'Blank contract generated',
        open: true,
      })
    }
  }

  const alertHungPdf = () => {
    setIsGeneratingBlankContract(false)
    updateToastState({
      color: 'error',
      message: 'Timeout while generating blank contract',
      open: true,
    })
  }

  useEffect(() => {
    notifications?.registerEvent(
      NotificationType.PdfProcessedEvent,
      pdfProcessed
    )
    const hungPdfGenerationChecker = setInterval(() => {
      setPdfGenerationStartTime((prev) => {
        if (
          !prev ||
          dayjs(prev).add(PDF_GENERATION_TIMEOUT, 'second').isAfter(Date.now())
        )
          return prev

        // If the pdf generation is taking too long, alert the user
        alertHungPdf()

        return undefined
      })
    }, 1000)

    return () => {
      notifications?.unregisterEvent(
        NotificationType.PdfProcessedEvent,
        pdfProcessed
      )
      clearInterval(hungPdfGenerationChecker)
    }
  }, [])

  useEffect(() => {
    validatePage()
  }, [formValues, pages])

  const fileDownloadOptions = useMemo(() => {
    if (!campaign?.blankSalesContract) return []
    const options = []

    if (campaign?.blankSalesContract) {
      options.push({
        text: DocumentTypesMap[DocumentTypes.BlankSalesContract],
        onClick: async () => {
          await openFileInNewTab(
            getAuthToken,
            campaign.blankSalesContract as UploadedDocument
          )
        },
      })
    }
    if (campaign?.blankSalesContractFlattened) {
      options.push({
        text: DocumentTypesMap[DocumentTypes.BlankSalesContractFlattened],
        onClick: async () => {
          await openFileInNewTab(
            getAuthToken,
            campaign.blankSalesContractFlattened as UploadedDocument
          )
        },
      })
    }

    return options
  }, [campaign?.blankSalesContract, campaign?.blankSalesContractFlattened])

  const onNotifyAgentToggle = (checked: boolean) => {
    setNotifyAgent(checked)
  }

  const renderTick = useCallback(
    (name: string) => {
      return (
        <div className="tickBox">
          {errorContainerNames.includes(name) ? (
            <AgxIconCircularWarning width={13} height={14} />
          ) : (
            <AgxIconCircularGreenTick width={13} height={14} />
          )}
        </div>
      )
    },
    [errorContainerNames]
  )

  const renderCompletionStatus = useCallback(() => {
    if (campaign?.address.state === AustralianState.QLD && campaignIsValid) {
      const completePanelsNum = pages.filter(
        (page) => !errorContainerNames.includes(page.name)
      ).length
      const totalPanelsNum = pages.length
      const allPanelsComplete = completePanelsNum === totalPanelsNum
      return (
        <>
          <div className="circularProgress">
            {allPanelsComplete ? (
              <AgxIconCircularGreenTick width={26} height={27} />
            ) : (
              <AgxIconCircularIncomplete width={26} height={27} />
            )}
          </div>
          <AgxColumn>
            <AgxBodyText extraClasses="label">Blank Contract</AgxBodyText>
            {allPanelsComplete ? (
              <AgxBodyText extraClasses="completionStatus">
                <span className="completionStatus--complete">Completed</span>
              </AgxBodyText>
            ) : (
              <AgxBodyText extraClasses="completionStatus">
                <span className="completionStatus--complete">
                  {completePanelsNum}
                </span>
                <span className="completionStatus--incomplete">
                  /{totalPanelsNum} completed
                </span>
              </AgxBodyText>
            )}
          </AgxColumn>
        </>
      )
    }
    return <AgxBodyText large>Blank Contract</AgxBodyText>
  }, [pages, errorContainerNames])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(campaign?.address)}`}
      isCampaignsSection={true}
    >
      <FormPrompt hasUnsavedChanges={isFormDirty} />
      <AgxToast selector="#agxToast" toastState={toastState} />
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.BLANKCONTRACT}
        isCampaigns={true}
      >
        <AgxColumn veryLargeGap>
          {isGeneratingBlankContract && (
            <div className="loadingAnimation">
              <div className="loadingAnimationInner">
                <RenderAnimation icon={RenderAnimationType.Settings} />
              </div>
            </div>
          )}

          <div ref={stickyHeaderRow}>
            <AgxRow
              spaceBetween
              extraClasses={`borderBottomContainer ${
                isGeneratingBlankContract ? 'disabledDiv' : ''
              }`}
            >
              <AgxRow largeGap>
                {renderCompletionStatus()}
                <AgxSwitch
                  dataTestId="liveSwitch"
                  id={'liveSwitch'}
                  onChange={toggleLiveBlankContract}
                  width={87}
                  dataOff="Offline"
                  dataOn="Live"
                  isChecked={campaign?.blankContractIsLive}
                  parentControlValue={true}
                />
              </AgxRow>
              <AgxRow largeGap>
                {showQldInfo && (
                  <AgxMultiOptionButton
                    dataTestId="btn-downloadContract"
                    id="downloadContract"
                    text="Download Contract"
                    medium
                    hollow
                    disabled={
                      !campaign?.blankSalesContract ||
                      !campaignIsValid ||
                      isGeneratingBlankContract ||
                      isLoadingFormConfig
                    }
                    options={fileDownloadOptions}
                    onClick={
                      fileDownloadOptions?.length === 1
                        ? () => fileDownloadOptions[0].onClick
                        : undefined
                    }
                  />
                )}
                <AgxButton
                  text="Save Changes"
                  medium
                  primary
                  disabled={
                    !campaignIsValid ||
                    isGeneratingBlankContract ||
                    isLoadingFormConfig
                  }
                  onClick={onSaveFormValues}
                  dataTestId="btn-saveChanges"
                />
              </AgxRow>
            </AgxRow>
          </div>
          <AgxRow veryLargeGap extraClasses={!showQldInfo ? 'column' : ''}>
            {campaignIsValid && showQldInfo ? (
              <div className="leftMenuContainer" ref={stickyLeftMenu}>
                <AgxColumn largeGap>
                  {pages?.map((panel) => (
                    <div key={slugify(panel.name)} className="leftMenu">
                      {renderTick(panel.name)}
                      <a
                        href={`#${slugify(panel.name)}`}
                        className="leftMenuLinkStyle"
                      >
                        <AgxBodyText small extraClasses="strong">
                          {panel.name}
                        </AgxBodyText>
                      </a>
                    </div>
                  ))}
                </AgxColumn>
              </div>
            ) : (
              <></>
            )}
            <div id="panelContainer" ref={panelContainer}>
              {!campaignIsValid ? (
                <AgxLabel extraClasses="loadingText_campaigns">
                  This campaign is not ready for blank contract generation
                </AgxLabel>
              ) : !isLoadingFormConfig && campaign ? (
                <AgxColumn extraLargeGap>
                  {pages?.map((panel) => {
                    return (
                      <div
                        key={`${slugify(panel.name)}`}
                        className={`${
                          panel.hideFormPageName ? '' : 'panelContainer'
                        }`}
                        id={slugify(panel.name)}
                      >
                        <AgxColumn extraLargeGap>
                          {!panel.hideFormPageName && (
                            <div className="panelTitleContainer">
                              <AgxBodyText medium>{panel.name}</AgxBodyText>
                            </div>
                          )}
                          {panel.elements &&
                            elementRenderer({
                              elements: filterDisplayElementHideCondition(
                                panel.elements,
                                formValues
                              ),
                              onChange,
                              campaign: campaign as CampaignDetailModel,
                              getAuthToken,
                              formValues,
                              validate,
                              featureToggles,
                            })}
                        </AgxColumn>
                      </div>
                    )
                  })}
                </AgxColumn>
              ) : (
                <></>
              )}
            </div>
          </AgxRow>
        </AgxColumn>

        {openErrorModal ? (
          <ErrorModal
            errorList={submitFormErrors}
            setShowModal={setOpenErrorModal}
          />
        ) : (
          <></>
        )}

        {openConfirmationModal ? (
          <SetBlankContractConfirmModal
            setShowModal={setOpenConfirmationModal}
            onConfirm={onSubmit}
            onNotifyAgentToggle={onNotifyAgentToggle}
          />
        ) : (
          <></>
        )}
      </ContentLayout>
    </PageLayout>
  )
}

export default ManageCampaign
