import { useContext, useEffect, useState, useMemo } from 'react'
import clsx from 'clsx'
import { MenuTitles } from 'constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  StaffGetMarketingPackageTemplates,
  StaffGetMarketingItemTemplates,
  DeleteMarketingPackageTemplate,
  GetAllAgencies,
  UpdateMarketingPackageTemplateOrder,
  CreateMarketingPackageTemplate,
} from 'services'
import { Agency } from 'types/agency'
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxHeader,
  Images,
} from '@urbanx/agx-ui-components'
import DeleteConfirmation from 'components/delete-confirmation/DeleteConfirmation'
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'
import SortableItem from 'components/sortable-item/SortableItem'
import EditMarketingPackage from './components/edit-marketing-package/EditMarketingPackage'
import EditItems from './components/edit-items/EditItems'
import './marketing.scss'
import { useAzureAuth } from 'hooks/useAzureAuth'
import {
  MarketingItemTemplate,
  MarketingPackageTemplate,
} from 'types/marketing'
import { useAgentsInAgency } from 'hooks'

const MarketingPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showMoreOptionsId, setShowMoreOptionsId] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [showEditItems, setShowEditItems] = useState(false)
  const [marketingPackages, setMarketingPackages] = useState<
    MarketingPackageTemplate[]
  >([])
  const [selectedPackage, setSelectedPackage] = useState<
    MarketingPackageTemplate | undefined
  >(undefined)
  const [, getAuthToken] = useAzureAuth()
  const { agents } = useAgentsInAgency()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const { data } = useQuery<MarketingPackageTemplate[] | undefined>({
    queryKey: [`packages-${agencyId}`, agencyId],
    queryFn: (queryKey) =>
      StaffGetMarketingPackageTemplates(queryKey, getAuthToken),
  })

  const { mutate: addPackage } = useMutation({
    mutationFn: CreateMarketingPackageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
    },
  })

  const { mutate: updateMarketingPkgOrder } = useMutation({
    mutationFn: UpdateMarketingPackageTemplateOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
    },
  })

  const { mutate: deletePackage } = useMutation({
    mutationFn: DeleteMarketingPackageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`packages-${agencyId}`, agencyId],
      })
    },
  })

  const { data: marketingItems } = useQuery<
    MarketingItemTemplate[] | undefined
  >({
    queryKey: [`packageItem-${agencyId}`, agencyId],
    queryFn: (queryKey) =>
      StaffGetMarketingItemTemplates(queryKey, getAuthToken),
  })

  useEffect(() => {
    if (data) setMarketingPackages(data)
  }, [data])

  useEffect(() => {
    if (!selectedAgency) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies])

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [`agentsInAgency-${agencyId}`, agencyId],
    })
  }, [])

  const onEditMarketingPackage = (mp: MarketingPackageTemplate) => {
    setSelectedPackage(mp)
    setShowDetails(true)
  }

  const onDeletePackageClick = (mp: MarketingPackageTemplate) => {
    setSelectedPackage(mp)
    setShowDeleteConfirmation(true)
  }

  const onDuplicatePackageClick = (mp: MarketingPackageTemplate) => {
    addPackage({
      AgencyId: agencyId || '',
      Order: marketingPackages.length,
      Name: `${mp.name} - Copy`,
      DisplayName: mp.displayName,
      IncludeRealEstateListing: mp.includeRealEstateListing,
      IncludeDomainListing: mp.includeDomainListing,
      DisplayItemisedPricingOnPdf: mp.displayItemisedPricingOnPdf,
      Items: mp.items,
      AddOns: mp.addOns,
      specialConditions: mp.specialConditions,
      assignedAgentIds: mp.assignedAgentIds,
      getAuthToken,
    })
    setShowMoreOptionsId('')
  }

  const onConfirmDelete = () => {
    deletePackage({
      AgencyId: selectedAgency?.id || '',
      MarketingPackageId: selectedPackage?.id || '',
      getAuthToken,
    })
    setShowDeleteConfirmation(false)
  }

  const onAddNewPackageClick = () => {
    setSelectedPackage(undefined)
    setShowDetails(true)
  }

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (!over || active.id === over.id) return

    setMarketingPackages((prev) => {
      const oldIndex = prev.findIndex((item) => item.id === active.id)
      const newIndex = prev.findIndex((item) => item.id === over.id)
      const updatedPackages = arrayMove(prev, oldIndex, newIndex)

      const marketingPackageIds = updatedPackages.map((item) => item.id)
      updateMarketingPkgOrder({
        marketingPackageIds,
        getAuthToken,
      })

      return updatedPackages
    })
  }

  const onEditItems = () => {
    setShowEditItems(true)
  }

  const marketingPackageItemClasses = clsx(
    'dragDropItem',
    'marketingPackageItem'
  )

  const renderMarketingPackages = (
    mp: MarketingPackageTemplate,
    index: number
  ) => {
    return (
      <SortableItem
        key={mp.id || `marketingItem-${mp.id}-${index}`}
        id={mp.id || `marketingItem-${mp.id}-${index}`}
        extraClasses={marketingPackageItemClasses}
      >
        <AgxRow spaceBetween centered>
          <AgxHeader size={4}>{mp.name}</AgxHeader>
          <div className="actionContainerTD">
            <AgxButton
              text="Manage"
              medium
              hollow
              onClick={() => onEditMarketingPackage(mp)}
            />
            <div className="moreOptions">
              <AgxButton
                text=""
                large
                naked
                leftIcon={<Images.More />}
                onClick={() => {
                  if (showMoreOptionsId !== mp.id) setShowMoreOptionsId(mp.id)
                  else setShowMoreOptionsId('')
                }}
              />
              {showMoreOptionsId.length > 0 && showMoreOptionsId === mp.id && (
                <div className="moreOptionsContent">
                  <div onClick={() => onDuplicatePackageClick(mp)}>
                    Duplicate
                  </div>
                  <div onClick={() => onDeletePackageClick(mp)}>Delete</div>
                </div>
              )}
            </div>
          </div>
        </AgxRow>
      </SortableItem>
    )
  }

  const activeAgents = useMemo(() => {
    return agents?.filter((a) => !a.softDeleted) || []
  }, [agents])

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle="Marketing Packages"
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.MARKETING_PACKAGES}
      >
        <AgxRow extraClasses="stretchContainerItems">
          {showEditItems && (
            <EditItems
              onBack={() => setShowEditItems(false)}
              existingItems={marketingItems?.map((item) => ({ ...item })) || []}
              agencyId={selectedAgency?.id || ''}
            />
          )}
          {showDetails && (
            <EditMarketingPackage
              onBack={() => setShowDetails(false)}
              marketingPackage={selectedPackage}
              marketingPackageOrder={
                !selectedPackage
                  ? marketingPackages?.length + 1
                  : selectedPackage.order
              }
              includedStandardInclusions={selectedPackage?.items || []}
              includedAddons={selectedPackage?.addOns || []}
              allItems={marketingItems || []}
              allAvailableAgents={activeAgents || []}
              agencyId={selectedAgency?.id || ''}
            />
          )}
          {!showEditItems && !showDetails && (
            <AgxRow fill veryLargeGap>
              <AgxColumn fill extraClasses="sectionStyle">
                <AgxHeader size={2}>{marketingItems?.length}</AgxHeader>
                <AgxColumn extraClasses="borderBottomContainer noBorder">
                  <AgxBodyText large>Marketing Items</AgxBodyText>
                </AgxColumn>
                <AgxRow>
                  <AgxButton
                    text="Edit Items"
                    medium
                    hollow
                    onClick={() => onEditItems()}
                  />
                </AgxRow>
              </AgxColumn>
              <AgxColumn fill extraClasses="sectionStyle">
                <AgxHeader size={2}>{marketingPackages?.length}</AgxHeader>
                <AgxColumn extraClasses="borderBottomContainer">
                  <AgxBodyText large>Marketing Packages</AgxBodyText>
                </AgxColumn>

                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleOnDragEnd}
                >
                  <SortableContext
                    items={marketingPackages
                      .map((item) => item.id)
                      .filter((id): id is string => id !== undefined)}
                    strategy={verticalListSortingStrategy}
                  >
                    {marketingPackages &&
                      marketingPackages.map((mp, index) =>
                        renderMarketingPackages(mp, index)
                      )}
                  </SortableContext>
                </DndContext>

                <AgxRow extraClasses="marketingPackageItem">
                  <AgxButton
                    text="New Package"
                    medium
                    hollow
                    onClick={() => onAddNewPackageClick()}
                  />
                </AgxRow>
              </AgxColumn>
            </AgxRow>
          )}

          <DeleteConfirmation
            name={selectedPackage?.name || ''}
            title="Delete marketing package?"
            lineOne="Are you sure you want to delete"
            lineTwo="This cannot be undone."
            primaryActionTitle="Permanently delete package"
            secondaryActionTitle="No, cancel"
            showPopUp={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
            onConfirm={() => onConfirmDelete()}
          />
        </AgxRow>
      </ContentLayout>
    </PageLayout>
  )
}

export default MarketingPage
