import {
  AgxRow,
  AgxColumn,
  AgxButton,
  AgxBodyText,
  DocumentTypes,
  DocumentTypesMap,
} from '@urbanx/agx-ui-components'
import { useQuery } from '@tanstack/react-query'
import { GetDocumentsForCampaigns } from 'services'
import { useContext } from 'react'
import { ContentLayout, PageLayout } from 'layout'
import { UserContext } from 'contexts'
import { MenuTitles } from 'constants/menu'
import { useSelectedAgency, useSelectedCampaign, useAzureAuth } from 'hooks'
import { DocumentGroup } from 'services/functions/campaigns/getDocumentsForCampaigns'
import DocumentsList from './DocumentsList'
import { openFileInNewTab } from 'utils/openFileInNewTab'
import './Submissions.scss'

const Submissions = () => {
  const user = useContext(UserContext)
  const [, getAuthToken] = useAzureAuth()
  const { agency } = useSelectedAgency()
  const { campaign } = useSelectedCampaign()

  const campaignDocumentsQueryKey = [`campaign-documents`, campaign?.campaignId]

  const { data: docInfoGroups, isLoading: areDocumentsLoading } = useQuery<
    DocumentGroup[] | undefined
  >({
    queryKey: campaignDocumentsQueryKey,
    queryFn: (ctx) =>
      GetDocumentsForCampaigns(ctx.queryKey[1] as string, getAuthToken),
    enabled: !!campaign?.campaignId,
  })

  const getDocumentTypeLabel = (documentType: string) =>
    DocumentTypesMap[documentType as DocumentTypes]

  const downloadAllDocuments = async () => {
    if (docInfoGroups)
      docInfoGroups.map((docGroup) =>
        docGroup.documents.forEach((docGroup) =>
          openFileInNewTab(getAuthToken, docGroup)
        )
      )
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={agency?.name || ''}
      currentPageTitle="Campaigns"
      isCampaignsSection={true}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.FILES}
        isCampaigns={true}
      >
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween extraClasses="borderBottomContainer">
            <AgxRow largeGap>
              <AgxBodyText large>Files</AgxBodyText>
            </AgxRow>
            <AgxButton
              text="Download all"
              medium
              hollow
              onClick={downloadAllDocuments}
            />
          </AgxRow>
          {!areDocumentsLoading && docInfoGroups ? (
            <DocumentsList
              documentsInfo={docInfoGroups}
              getDocumentTypeLabel={getDocumentTypeLabel}
            />
          ) : (
            <AgxBodyText medium>No documents found</AgxBodyText>
          )}
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default Submissions
