import { JSX, ReactElement } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AustralianState } from '@urbanx/agx-ui-components'
import slugify from 'slugify'
import {
  Menu,
  MenuTitles,
  StaffMenu,
  BlankContractMenu,
  ContractsMenu,
} from 'constants/menu'
import { SideMenu } from 'types/commonTypes'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { useSelectedAgency } from 'hooks'
import './ContentLayout.scss'

export interface ContentLayoutProps {
  children: ReactElement | ReactElement[]
  isStaff?: boolean
  isCampaigns?: boolean
  isContracts?: boolean
  activeMenu?: MenuTitles
  hasSideMenu?: boolean
  hasSideBar?: boolean
  sideBarContent?: () => JSX.Element
}

const ContentLayout = (props: ContentLayoutProps) => {
  const {
    hasSideMenu,
    hasSideBar,
    sideBarContent,
    activeMenu,
    isStaff,
    isCampaigns,
    isContracts,
    children,
  } = props
  const { agencyId, campaignId, contractId } = useParams()
  const [, , userHasRequiredRoles] = useAzureAuth()
  const { agency: selectedAgency, isLoading, isFetched } = useSelectedAgency()
  const navigate = useNavigate()

  const generateLink = (pageLink: string) => {
    if (pageLink === '') return '/'

    if (agencyId) {
      if (contractId) {
        return `/${agencyId}/campaigns/${campaignId}/contracts/${contractId}/${pageLink}`
      } else if (campaignId) {
        return `/${agencyId}/campaigns/${campaignId}/${pageLink}`
      }

      return `/${agencyId}/${pageLink}`
    }
    return pageLink || '#'
  }

  if (agencyId && (!isFetched || isLoading)) return null

  const agencyStates =
    selectedAgency?.companyDetails?.licences
      ?.filter((l) => !!l?.state)
      .map((l) => l.state as number) ?? []

  const renderSideMenu = (menuItems: SideMenu[]) => {
    const selectedStates = agencyStates.map(
      (index) => Object.values(AustralianState)[index]
    )

    return menuItems
      .filter((menu) => userHasRequiredRoles(menu.requiredRoles))
      .filter(
        (menu) =>
          menu.includeOnlyInState.length === 0 ||
          menu.includeOnlyInState.some((state) =>
            selectedStates.includes(state)
          )
      )
      .map((menu, index) => (
        <li
          onClick={() => {
            navigate(generateLink(menu.pageLink ?? ''))
          }}
          key={`sideMenu_${index}`}
          className={`menuItem ${
            activeMenu && activeMenu === menu.title ? 'active' : ''
          }`}
        >
          <Link
            data-testid={`link-${slugify(menu.title)}`}
            to={generateLink(menu.pageLink ?? '')}
          >
            {menu.title}
          </Link>
        </li>
      ))
  }

  return (
    <div
      className={
        hasSideMenu || hasSideBar ? 'contentWithMenu' : 'contentWithoutMenu'
      }
    >
      {hasSideMenu && (
        <ul className="menuContainer">
          {isStaff && renderSideMenu(StaffMenu)}
          {isCampaigns && renderSideMenu(BlankContractMenu)}
          {isContracts && renderSideMenu(ContractsMenu)}
          {!isStaff && !isCampaigns && !isContracts && renderSideMenu(Menu)}
        </ul>
      )}
      {hasSideBar && sideBarContent && (
        <div className="menuContainer">{sideBarContent()}</div>
      )}
      <div className="contentContainer">{children}</div>
    </div>
  )
}

export default ContentLayout
