import { createBrowserRouter } from 'react-router-dom'
import MainLayout from 'layout/MainLayout'
import {
  BrandingPage,
  CampaignPage,
  DashboardPage,
  AgencyDetailsPage,
  MarketingPage,
  StaffLicensePage,
  StaffMemberPage,
  TeamPage,
  ManageCampaign,
  Permissions,
  IntegrationsPage,
  StaffReporting,
  CampaignMarketing,
  Contracts,
  DocumentDefaults,
  Partners,
  Partner,
  Network,
  ListingsPage,
  PartnershipPage,
  Submissions,
  ContractFiles,
} from 'pages'
import { PageNotFoundPage, UnexpectedErrorPage } from 'pages/errorPages'
import { Pages } from './menu'
import { AuthenticatedLayout } from 'layout/AuthenticatedLayout'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { FormType } from '@urbanx/agx-ui-components'
import ProtectedRoute from 'layout/ProtectedRoute'
import { StaffRole } from 'types/staff'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
      retryDelay: 3000,
      networkMode: 'always',
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(error)
    },
  }),
})

const router = createBrowserRouter([
  {
    path: Pages.DASHBOARD,
    element: <AuthenticatedLayout />,
    children: [
      {
        path: '',
        element: <MainLayout />,
        children: [
          {
            path: Pages.DASHBOARD,
            element: (
              <QueryClientProvider client={queryClient}>
                <DashboardPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.BRANDING}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <BrandingPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.TEAMS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <TeamPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.PARTNERSHIP}/*`,
            element: (
              <ProtectedRoute
                element={
                  <QueryClientProvider client={queryClient}>
                    <PartnershipPage />
                  </QueryClientProvider>
                }
                requiredRoles={[
                  StaffRole.BusinessManager,
                  StaffRole.SuperAdmin,
                  StaffRole.Executive,
                ]}
              />
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.INTEGRATIONS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <IntegrationsPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.MARKETING}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <MarketingPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <CampaignPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}/:campaignId/files`,
            element: (
              <QueryClientProvider client={queryClient}>
                <Submissions />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}/:campaignId/manage`,
            element: (
              <QueryClientProvider client={queryClient}>
                <ManageCampaign formType={FormType.BlankContract} />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}/:campaignId/${Pages.PERMISSIONS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <Permissions />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}/:campaignId/${Pages.CAMPAIGN_MARKETING}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <CampaignMarketing />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}/:campaignId/${Pages.CONTRACTS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <Contracts />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.CAMPAIGNS}/:campaignId/${Pages.CONTRACTS}/:contractId/${Pages.FILES}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <ContractFiles />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.AGENCY_DETAILS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <AgencyDetailsPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.DOCUMENT_DEFAULTS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <DocumentDefaults />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: Pages.STAFF,
            element: (
              <QueryClientProvider client={queryClient}>
                <StaffMemberPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: Pages.STAFF_LICENSE,
            element: (
              <QueryClientProvider client={queryClient}>
                <StaffLicensePage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: '*',
            element: (
              <QueryClientProvider client={queryClient}>
                <PageNotFoundPage />
              </QueryClientProvider>
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: '/executive-report',
            element: (
              <ProtectedRoute
                element={
                  <QueryClientProvider client={queryClient}>
                    <StaffReporting />
                  </QueryClientProvider>
                }
                requiredRoles={[StaffRole.Executive]}
              />
            ),

            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: '/partners', // The path for the staff portal page
            element: (
              <ProtectedRoute
                element={
                  <QueryClientProvider client={queryClient}>
                    <Partners />
                  </QueryClientProvider>
                }
                requiredRoles={[
                  StaffRole.BusinessManager,
                  StaffRole.SuperAdmin,
                  StaffRole.Executive,
                ]}
              />
            ),
            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: '/partner/agency/:entityId',
            element: (
              <ProtectedRoute
                element={
                  <QueryClientProvider client={queryClient}>
                    <Partner individual={false} />
                  </QueryClientProvider>
                }
                requiredRoles={[
                  StaffRole.BusinessManager,
                  StaffRole.SuperAdmin,
                  StaffRole.Executive,
                ]}
              />
            ),

            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: '/partner/individual/:entityId',
            element: (
              <ProtectedRoute
                element={
                  <QueryClientProvider client={queryClient}>
                    <Partner individual />
                  </QueryClientProvider>
                }
                requiredRoles={[
                  StaffRole.BusinessManager,
                  StaffRole.SuperAdmin,
                  StaffRole.Executive,
                ]}
              />
            ),

            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: '/network',
            element: (
              <ProtectedRoute
                element={
                  <QueryClientProvider client={queryClient}>
                    <Network />
                  </QueryClientProvider>
                }
                requiredRoles={[StaffRole.Executive]}
              />
            ),

            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
          {
            path: `/:agencyId/${Pages.LISTINGS}`,
            element: (
              <QueryClientProvider client={queryClient}>
                <ListingsPage />
              </QueryClientProvider>
            ),

            errorElement: (
              <QueryClientProvider client={queryClient}>
                <UnexpectedErrorPage />
              </QueryClientProvider>
            ),
          },
        ],
      },
    ],
  },
])

export default router
