import {
  useState,
  useEffect,
  useCallback,
  useContext,
  Suspense,
  useMemo,
} from 'react'
import {
  AgxRow,
  AgxColumn,
  AgxHeader,
  AgxLabel,
  AgxButton,
  AgxTextInput,
  AgxBodyText,
  AgxToast,
  AgxDivider,
  AgxFilterableTableHeader,
  Images,
  CampaignStage,
  Option,
  AustralianState,
  AgxRadioGroup,
  cleanFullAddress,
} from '@urbanx/agx-ui-components'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import {
  GetAllAgencies,
  GetCampaignsForAgency,
  GetArchivedCampaigns,
  BeginCampaign,
  PerformCampaignAction,
} from 'services'
import { AgxToastState } from 'types/commonTypes'
import {
  Campaign,
  CampaignTableColumn,
  CampaignTableColumnNames,
  CampaignColumnStatus,
  CampaignsTab,
  CampaignStageAction,
} from 'types/campaigns'
import { Agency } from 'types/agency'
import { MenuTitles } from 'constants/menu'
import CampaignOptions from './components/options/CampaignOptions'
import Popup from 'components/Popup/Popup'
import { cleanTwoLineAddressWithoutState } from 'helpers/address'
import { getEnumValue } from 'helpers/enumHelper'
import { useAgentsInAgency, useAzureAuth } from 'hooks'
import Live from './assets/live.svg'
import Offline from './assets/offline.svg'
import placeHolderImage from 'assets/images/placeholder-property.png'
import './Campaigns.scss'

interface Column {
  id: string
  name: string
  options: string[] | object[]
}

const CampaignsPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const [searchText, setSearchText] = useState('')
  const [currentTab, setCurrentTab] = useState(CampaignsTab.Campaigns)
  const [shouldResetFilters, setShouldResetFilters] = useState(false)
  const [allCampaignsData, setAllCampaignsData] = useState<Campaign[]>([])
  const [allArchivedCampaignsData, setAllArchivedCampaignsData] = useState<
    Campaign[]
  >([])
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const user = useContext(UserContext)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const navigate = useNavigate()
  const [, getAuthToken] = useAzureAuth()
  const { agents } = useAgentsInAgency()
  const [columns, setColumns] = useState<Column[]>([])
  const [filteredData, setFilteredData] = useState<Campaign[]>([])
  const [showPopup, setShowPopup] = useState(false)
  const [selectedAustralianState, setSelectedAustralianState] = useState<
    Option | undefined
  >()

  const selectedAgencyStatesOptions = selectedAgency
    ? selectedAgency.states.map(
        (index) =>
          ({
            label: Object.values(AustralianState)[index],
            value: `${index}`,
          }) as Option
      )
    : []

  const queryKeys = {
    campaigns: [`campaigns-${agencyId}`, agencyId],
    archivedCampaigns: [`archivedCampaigns-${agencyId}`, agencyId],
  }

  const { data: campaigns, isLoading: isLoadingCampaigns } = useQuery<
    Campaign[] | undefined
  >({
    queryKey: queryKeys.campaigns,
    queryFn: (queryKey) => GetCampaignsForAgency(queryKey, getAuthToken),
  })

  const { data: archivedCampaigns, isLoading: isLoadingArchivedCampaigns } =
    useQuery<Campaign[] | undefined>({
      queryKey: queryKeys.archivedCampaigns,
      queryFn: (queryKey) => GetArchivedCampaigns(queryKey, getAuthToken),
    })

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
    enabled: !selectedAgency,
  })

  const toastMessage = (action: CampaignStageAction) => {
    switch (action) {
      case CampaignStageAction.Archive:
        return 'Campaign moved to Archive'
      case CampaignStageAction.Restore:
        return 'Campaign restored successfully'
      case CampaignStageAction.MarkAsSold:
        return 'Campaign status updated to Sold'
      case CampaignStageAction.MarkAsUnderContract:
        return 'Campaign status updated to Under Contract'
      case CampaignStageAction.Withdraw:
        return 'Campaign status updated to Withdrawn'
      case CampaignStageAction.RevertToCurrent:
        return 'Campaign status updated to Current'
      default:
        return 'Campaign action performed successfully'
    }
  }

  const { mutate: setPerformCampaignAction } = useMutation({
    mutationFn: PerformCampaignAction,
    onSuccess: (_, { campaignStageAction: action }) => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKeys.campaigns }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.archivedCampaigns,
        }),
      ])
      updateToastState({
        color: 'success',
        message: toastMessage(action),
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error performing campaign action',
        open: true,
      })
    },
  })

  const isArchivedTab = currentTab !== CampaignsTab.Campaigns

  useEffect(() => {
    if (!selectedAgency) {
      setSelectedAgency(agencies?.find((a) => a.id === agencyId))
    }
  }, [agencies])

  const getAllAgentNames = useMemo((): string[] => {
    return (agents || [])
      .filter((agent) => agent?.name?.firstName && agent?.name?.lastName)
      .map((agent) => `${agent.name?.firstName} ${agent.name?.lastName}`)
  }, [agents])

  const getAgentNameByEmailId = useCallback(
    (emailId: string) => {
      const agent = agents?.find((agent) => agent.id === emailId)?.name
      return agent ? `${agent?.firstName} ${agent?.lastName}` : '--'
    },
    [agents]
  )

  const filterCampaigns = (
    data: Campaign[] | undefined,
    searchText: string
  ): Campaign[] => {
    return data
      ? data.filter(
          (campaign) =>
            cleanFullAddress(campaign?.address)
              ?.toLocaleLowerCase()
              ?.includes(searchText.toLocaleLowerCase()) ?? false
        )
      : []
  }

  useEffect(() => {
    const data = isArchivedTab ? archivedCampaigns : campaigns
    const filteredData = searchText ? filterCampaigns(data, searchText) : data

    if (filteredData) {
      if (!isArchivedTab) setAllCampaignsData(filteredData)
      else setAllArchivedCampaignsData(filteredData)

      setFilteredData(filteredData)
    }

    const allData = !isArchivedTab ? campaigns : archivedCampaigns || []

    const uniqueStatusOptions = Array.from(
      new Set(allData?.map((row) => row.stage))
    )

    // Update columns with filtering options
    const updatedColumns: Column[] = [
      {
        id: CampaignTableColumn.Campaign,
        name: CampaignTableColumnNames.Campaign,
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: CampaignTableColumn.Status,
        name: CampaignTableColumnNames.Status,
        options: !isArchivedTab
          ? [
              CampaignColumnStatus.Agreement,
              CampaignColumnStatus.CurrentListing,
              CampaignColumnStatus.UnderContract,
            ]
          : uniqueStatusOptions,
      },
      {
        id: CampaignTableColumn.LeadAgent,
        name: CampaignTableColumnNames.LeadAgent,
        options: getAllAgentNames,
      },
      {
        id: CampaignTableColumn.SecondAgent,
        name: CampaignTableColumnNames.SecondAgent,
        options: getAllAgentNames,
      },
      {
        id: CampaignTableColumn.BlankContract,
        name: CampaignTableColumnNames.BlankContract,
        options: !isArchivedTab
          ? [CampaignColumnStatus.Live, CampaignColumnStatus.Offline]
          : [],
      },
      {
        id: '',
        name: '',
        options: [],
      },
      {
        id: CampaignTableColumn.Actions,
        name: '',
        options: [],
      },
    ]

    const filteredColumns = isArchivedTab
      ? updatedColumns.filter(
          (column) =>
            column.id !== CampaignTableColumn.BlankContract &&
            column.id !== CampaignTableColumn.Actions
        )
      : updatedColumns

    setColumns(filteredColumns)
  }, [searchText, campaigns, archivedCampaigns, isArchivedTab])

  const applyFilters = (filters: { [key: string]: string[] }) => {
    const dataToFilter = isArchivedTab
      ? allArchivedCampaignsData
      : allCampaignsData

    if (!dataToFilter) {
      setFilteredData([])
      return
    }

    // Apply filters to the data
    const filteredRows = dataToFilter.filter((row) =>
      Object.entries(filters).every(([column, selectedValues]) => {
        if (column === CampaignTableColumn.BlankContract) {
          const blankContractStatus = row.blankContractIsLive
            ? CampaignColumnStatus.Live
            : CampaignColumnStatus.Offline

          return (
            selectedValues.length === 0 ||
            selectedValues.some((value) => blankContractStatus === value)
          )
        }

        if (column === CampaignTableColumn.Status && !isArchivedTab) {
          const rowStage = getEnumValue(CampaignStage, row.stage)
          if (
            rowStage === CampaignStage.AgreementDraft ||
            rowStage === CampaignStage.AgreementAgentSigning ||
            rowStage === CampaignStage.AgreementVendorSigning ||
            rowStage === CampaignStage.AgreementSignedByAllParties
          )
            return (
              selectedValues.length === 0 ||
              selectedValues.includes(CampaignColumnStatus.Agreement)
            )
          if (rowStage === CampaignStage.ListingCurrent)
            return (
              selectedValues.length === 0 ||
              selectedValues.includes(CampaignColumnStatus.CurrentListing)
            )
          if (rowStage === CampaignStage.ListingUnderContract)
            return (
              selectedValues.length === 0 ||
              selectedValues.includes(CampaignColumnStatus.UnderContract)
            )
        }

        const cellValue =
          column === CampaignTableColumn.LeadAgent ||
          column === CampaignTableColumn.SecondAgent
            ? getAgentNameByEmailId(row[column])
            : column.includes('.')
              ? column.split('.').reduce((obj, key) => (obj as any)?.[key], row)
              : (row as any)[column]

        const stringCellValue = String(cellValue).toLowerCase()

        return (
          selectedValues.length === 0 ||
          selectedValues.some(
            (value) => stringCellValue === value.toLowerCase()
          )
        )
      })
    )

    setFilteredData(filteredRows)
  }

  const onShowSubmissions = (campaign: Campaign) => {
    navigate(`/${agencyId}/campaigns/${campaign.id}/files`)
  }

  const onPerformCampaignAction = (
    campaign: Campaign,
    campaignAction: CampaignStageAction
  ) => {
    setPerformCampaignAction({
      getAuthToken,
      campaignId: campaign.id,
      campaignStageAction: campaignAction,
    })
  }

  const { mutate: beginCampaignValues } = useMutation({
    mutationFn: BeginCampaign,
    onSuccess: (data) => {
      if (data?.campaingId) {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: [`campaigns-${agencyId}`, agencyId],
          }),
        ])
        if (!isLoadingCampaigns) {
          navigate(`/${agencyId}/campaigns/${data?.campaingId}/manage`)
        }
      }
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error begin campaign',
        open: true,
      })
    },
  })

  const onBeginCampaign = (selectedState?: Option) => {
    const australianStateValue = selectedState
      ? selectedState.value
      : selectedAustralianState?.value

    if (!agencyId || !australianStateValue) return

    beginCampaignValues({
      AgencyId: agencyId,
      State: parseInt(australianStateValue),
      getAuthToken,
    })
  }

  const onNewCampaignClicked = () => {
    if (selectedAgencyStatesOptions.length > 1) {
      setShowPopup(true)
    } else {
      setSelectedAustralianState(selectedAgencyStatesOptions[0])
      onBeginCampaign(selectedAgencyStatesOptions[0])
    }
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={MenuTitles.CAMPAIGNS}
      agencyLogoUrl={selectedAgency?.logoUrl}
      agencyBackgroundColor={selectedAgency?.backgroundColor}
    >
      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.CAMPAIGNS}>
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn>
          <AgxRow spaceBetween centered extraClasses="filters__campaigns">
            <AgxRow extraClasses="filterTabs__campaigns">
              <AgxButton
                text="All Campaigns"
                large
                hollow
                onClick={() => {
                  setCurrentTab(CampaignsTab.Campaigns)
                  setShouldResetFilters(true)
                }}
                extraClasses={`currentTab ${isArchivedTab ? 'formerTab' : ''}`}
                naked
              />
              <AgxButton
                text="Archived"
                large
                hollow
                onClick={() => {
                  setCurrentTab(CampaignsTab.Archived)
                  setShouldResetFilters(true)
                }}
                extraClasses={`currentTab ${!isArchivedTab ? 'formerTab' : ''}`}
                naked
              />
            </AgxRow>
            <AgxRow>
              <AgxRow>
                <AgxTextInput
                  id="campaignSearch"
                  label=""
                  defaultValue={searchText}
                  onInputValueChange={({ value }: { value: string }) =>
                    setSearchText(value)
                  }
                  noHeader
                  noOptionalLabel
                  placeholder="Search"
                  extraClasses="searchText__campaigns"
                />
                <AgxButton
                  text="New Campaign"
                  medium
                  hollow
                  onClick={() => onNewCampaignClicked()}
                  dataTestId="btn-newCampaign"
                />
              </AgxRow>
            </AgxRow>
          </AgxRow>
          <AgxDivider />
          <AgxRow>
            <table
              className="campaignsTable"
              width={'100%'}
              style={{ paddingBottom: 30 }}
            >
              <tbody>
                <AgxFilterableTableHeader
                  columns={columns}
                  shouldResetFilters={shouldResetFilters}
                  setShouldResetFilters={setShouldResetFilters}
                  onFilterChange={applyFilters}
                />
                {isLoadingCampaigns || isLoadingArchivedCampaigns ? (
                  <Suspense>
                    <tr>
                      <td colSpan={11}>
                        <AgxLabel extraClasses="loadingText_campaigns">
                          Loading Campaigns...
                        </AgxLabel>
                      </td>
                    </tr>
                  </Suspense>
                ) : (
                  <>
                    {filteredData?.map((campaign, index) => {
                      const [addressLineOne, addressLineTwo] =
                        cleanTwoLineAddressWithoutState(campaign.address)
                      const stageValue = getEnumValue(
                        CampaignStage,
                        campaign.stage
                      )
                      const [stagePartOne, stagePartTwo] = stageValue
                        .split('•')
                        .map((part) => part.trim())
                      return (
                        <tr key={index} className="tableRowStyle">
                          <td
                            colSpan={3}
                            align="left"
                            onClick={() => onShowSubmissions(campaign)}
                          >
                            <img
                              className={`propertyImage${
                                campaign.completionState?.completionType
                                  ? ' archived'
                                  : ''
                              }`}
                              width={60}
                              src={
                                campaign.propertyImageUrls?.large ??
                                placeHolderImage
                              }
                            />
                          </td>
                          <td
                            colSpan={3}
                            align="left"
                            onClick={() => onShowSubmissions(campaign)}
                          >
                            <AgxHeader size={4}>
                              {addressLineOne ? (
                                <>
                                  {addressLineOne},<br />
                                  {addressLineTwo}
                                </>
                              ) : (
                                <>
                                  Address <br />
                                  Unidentified
                                </>
                              )}
                            </AgxHeader>
                          </td>
                          <td
                            align="left"
                            onClick={() => onShowSubmissions(campaign)}
                          >
                            <AgxBodyText
                              small
                              dataTestId={`campaign-${index}-status-${getEnumValue(
                                CampaignStage,
                                campaign.stage
                              )}`}
                            >
                              {stagePartOne}
                              <br />
                              {stagePartTwo}
                            </AgxBodyText>
                          </td>
                          <td
                            align="left"
                            onClick={() => onShowSubmissions(campaign)}
                          >
                            <span className="iconLabel">
                              <Images.PersonOutline />
                              {getAgentNameByEmailId(campaign.leadAgentId) && (
                                <AgxBodyText small>
                                  {getAgentNameByEmailId(campaign.leadAgentId)}
                                </AgxBodyText>
                              )}
                            </span>
                          </td>
                          <td
                            align="left"
                            onClick={() => onShowSubmissions(campaign)}
                          >
                            <span className="iconLabel">
                              <Images.PeopleOutline />
                              {getAgentNameByEmailId(
                                campaign.secondAgentId
                              ) && (
                                <AgxBodyText small>
                                  {getAgentNameByEmailId(
                                    campaign.secondAgentId
                                  )}
                                </AgxBodyText>
                              )}
                            </span>
                          </td>
                          <td align="left">
                            {isArchivedTab && (
                              <AgxButton
                                text="Restore"
                                medium
                                hollow
                                onClick={() =>
                                  onPerformCampaignAction(
                                    campaign,
                                    CampaignStageAction.Restore
                                  )
                                }
                                extraClasses="restoreButton"
                              />
                            )}
                            {!isArchivedTab &&
                              (campaign?.blankContractIsLive ? (
                                <>
                                  <Live />
                                  <AgxBodyText extraClasses="contractStatus">
                                    Live
                                  </AgxBodyText>
                                </>
                              ) : (
                                <>
                                  <Offline />
                                  <AgxBodyText
                                    neutralGrayColor
                                    extraClasses="contractStatus"
                                  >
                                    Offline
                                  </AgxBodyText>
                                </>
                              ))}
                          </td>
                          {!isArchivedTab && (
                            <td
                              align="left"
                              valign="middle"
                              className="options"
                            >
                              <CampaignOptions
                                key={campaign.id}
                                selectedCampaign={campaign}
                                isArchived={isArchivedTab}
                                onPerformCampaignAction={
                                  onPerformCampaignAction
                                }
                              />
                            </td>
                          )}
                        </tr>
                      )
                    })}
                    {filteredData.length === 0 && (
                      <tr>
                        <td colSpan={11}>
                          <AgxLabel extraClasses="loadingText_campaigns">
                            No Campaigns found
                          </AgxLabel>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </AgxRow>
        </AgxColumn>
      </ContentLayout>
      {showPopup ? (
        <Popup
          title="Select the state the property is located in:"
          isOpen={showPopup}
          centered
          onClose={() => setShowPopup(false)}
          actionButtonsCentered={true}
          actionButtons={[
            {
              title: 'Start',
              isPrimary: true,
              clickHandler: () => onBeginCampaign(),
            },
            {
              title: 'Cancel',
              clickHandler: () => setShowPopup(false),
            },
          ]}
          size={{ widthInPX: 600 }}
        >
          <AgxColumn>
            <AgxRadioGroup
              id={'statesRadio'}
              label={''}
              options={selectedAgencyStatesOptions}
              onValueChanged={({ value }) =>
                setSelectedAustralianState(
                  selectedAgencyStatesOptions.find(
                    (option) => option.value === value
                  )
                )
              }
            />
          </AgxColumn>
        </Popup>
      ) : (
        <div></div>
      )}
    </PageLayout>
  )
}

export default CampaignsPage
