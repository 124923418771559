import React, { useState, useEffect, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxFileUpload,
  DocumentTypes,
  UploadedDocument,
  Images,
  useDeepCompareEffect,
} from '@urbanx/agx-ui-components'
import {
  EditablePdfButtonGroup,
  EditablePdfDocumentModel,
} from './components/types'
import EditPdfDialog from './components/EditPdfDialog'
import './EditablePdfUpload.scss'

interface Props {
  id: string
  title?: string
  readonly?: boolean
  required?: boolean
  requiredPdfFields?: string[]
  validate?: boolean
  defaultValue?: EditablePdfDocumentModel[]
  campaignId: string
  documentType: DocumentTypes
  multiple?: boolean
  buttonGroups: EditablePdfButtonGroup[]
  onValueChanged: (change: {
    id: string
    value?: EditablePdfDocumentModel[]
  }) => void
  onFileTypeSelected?: (select: string) => void
  editFile?: (...args: any) => Promise<any>
  uploadFile?: (...args: any) => Promise<any>
  deleteFile?: (
    authToken: string,
    filePath: string
  ) => Promise<number | undefined>
  getAuthToken: () => Promise<string | void>
  getFileLink?: (authToken: string, filePath: string) => Promise<any>
  getLicenseKey?: (authToken: string) => Promise<string | undefined>
}

const EditablePdfUpload = (props: Props) => {
  const {
    id,
    title = '',
    getAuthToken,
    readonly = false,
    defaultValue,
    campaignId,
    documentType,
    onValueChanged,
    required,
    requiredPdfFields = [],
    validate,
    uploadFile,
    editFile,
    deleteFile,
    getFileLink,
    buttonGroups,
    getLicenseKey,
    multiple,
  } = props

  const [editablePdfUploadFiles, setEditablePdfUploadFiles] = useState<
    EditablePdfDocumentModel[]
  >(defaultValue ?? [])

  const [editingPdfDocument, setEditingPdfDocument] = useState<
    EditablePdfDocumentModel | undefined
  >(undefined)

  const editingFieldsRef = useRef<Record<string, string[]>>({})
  const [errors, setErrors] = useState<Record<string, string> | null>(null)

  const noDocumentFieldsErrorMessage = 'Signature fields not mapped'

  const handleFieldsUpdated = (fields: string[]) => {
    const documentId = editingPdfDocument?.uploadedDocument?.documentId

    if (documentId) {
      editingFieldsRef.current[documentId] = fields
    }
  }

  useEffect(() => {
    setEditablePdfUploadFiles(defaultValue ?? [])
  }, [defaultValue])

  const handleFileUpload = (files: UploadedDocument[]) => {
    onValueChanged({
      id,
      value: files?.map((doc) => {
        const existingDocument = editablePdfUploadFiles?.find(
          (d) => d.uploadedDocument?.documentId === doc?.documentId
        )
        if (existingDocument) {
          return {
            uploadedDocument: doc,
            documentFields: existingDocument.documentFields,
          }
        }
        return { uploadedDocument: doc, documentFields: [] }
      }),
    })
  }

  const handleDocumentSaved = () => {
    setEditablePdfUploadFiles((prevState) => {
      const updatedEditablePdfUploadFiles = prevState.map((doc) =>
        doc.uploadedDocument.documentId ===
        editingPdfDocument?.uploadedDocument?.documentId
          ? {
              ...doc,
              documentFields:
                editingFieldsRef.current[
                  editingPdfDocument?.uploadedDocument?.documentId
                ],
            }
          : doc
      )

      onValueChanged({ id, value: updatedEditablePdfUploadFiles })

      return updatedEditablePdfUploadFiles
    })
  }

  useDeepCompareEffect(() => {
    const arePdfsUploaded = editablePdfUploadFiles.some(
      (file) => file.uploadedDocument
    )
    const areRequiredFieldsPresent =
      Object.values(editingFieldsRef.current).length > 0 &&
      Object.values(editingFieldsRef.current).some(
        (documentFields) => documentFields.length === 0
      ) &&
      requiredPdfFields.every((field) =>
        Object.values(editingFieldsRef.current).every((documentFields) =>
          documentFields.includes(field)
        )
      )

    const errors: Record<string, string> = editablePdfUploadFiles.reduce<
      Record<string, string>
    >((acc, file) => {
      const documentId = file.uploadedDocument?.documentId
      const missingFields = requiredPdfFields.filter(
        (field) => !file.documentFields?.includes(field)
      )

      if (missingFields.length > 0) {
        if (multiple && editablePdfUploadFiles.length >= 1) {
          acc[documentId] = file.uploadedDocument?.fileName
        } else {
          acc[documentId] = noDocumentFieldsErrorMessage
        }
      }

      return acc
    }, {})

    if (required && !arePdfsUploaded) {
      setErrors(null)
    } else if (!areRequiredFieldsPresent) {
      setErrors(errors)
    } else {
      setErrors(null)
    }
  }, [validate, required, requiredPdfFields, editablePdfUploadFiles])

  return (
    <AgxColumn largeGap fill>
      <EditPdfDialog
        key={`${id}-edit-pdf-dialog-${editingPdfDocument?.uploadedDocument?.documentId}`}
        campaignId={campaignId}
        uploadedDocument={editingPdfDocument?.uploadedDocument}
        buttonGroups={buttonGroups}
        getAuthToken={getAuthToken}
        getFileLink={getFileLink}
        onFieldsUpdated={handleFieldsUpdated}
        editFile={editFile}
        getLicenseKey={getLicenseKey}
        onDocumentSaved={handleDocumentSaved}
        onDialogClosed={() => setEditingPdfDocument(undefined)}
      />
      <AgxFileUpload
        id={id}
        key={`${id}`}
        fileData={editablePdfUploadFiles
          ?.map((doc) => doc?.uploadedDocument)
          .filter(Boolean)}
        campaignId={campaignId}
        documentType={documentType}
        onValueChanged={({ value }) => handleFileUpload(value)}
        multiple={multiple ?? false}
        getAuthToken={getAuthToken}
        extensions={['pdf']}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        getFileLink={getFileLink}
        required={required}
        readonly={readonly}
        title={title}
        validate={validate}
        customIcon={<Images.EditOutline />}
        onCustomIconClick={(uploadedFile) =>
          setEditingPdfDocument(
            editablePdfUploadFiles?.find(
              (doc) =>
                doc?.uploadedDocument?.documentId === uploadedFile?.documentId
            )
          )
        }
      />
      {!isEmpty(errors) &&
        Object.entries(errors).map(([key, error]) => (
          <AgxRow fill mediumGap key={`error-${key}`}>
            <Images.AlertCircle />
            <AgxBodyText small extraClasses="error">
              {error}
            </AgxBodyText>
          </AgxRow>
        ))}
    </AgxColumn>
  )
}

export default EditablePdfUpload
