import React, { useContext, useEffect, useState } from 'react'
import { MenuTitles } from 'constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  AgxTextInput,
  AgxDatePicker,
  AgxSelect,
  AgxColumn,
  AgxRow,
  AgxLabel,
  AgxButton,
  AgxToast,
  AgxPhoneNumberInput,
} from '@urbanx/agx-ui-components'
import {
  Address,
  CompanyDetails,
  LicenceDetails,
  UrbanXDetails,
} from 'types/agency'
import { GetUrbanXDetails, UpdateUrbanXDetails } from 'services'
import { AgxToastState } from 'types/commonTypes'
import { useAzureAuth } from 'hooks/useAzureAuth'

type UrbanXDetailsKeys = keyof UrbanXDetails
type CompanyDetailsKeys = keyof CompanyDetails
type CompanyAddressKeys = keyof Address

const StaffLicense = () => {
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const [selectedAgency] = useContext(AgencyContext)
  const [urbanXDetailsForm, setUrbanXDetailsForm] = useState<UrbanXDetails>(
    new UrbanXDetails()
  )
  const [licenceDetails, setLicenceDetails] = useState<LicenceDetails>({
    expiryDate: '',
    licenceNumber: '',
  })
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })
  const [, getAuthToken] = useAzureAuth()
  const isStaff = true

  const { data: urbanXDetails } = useQuery<UrbanXDetails | undefined>({
    queryKey: [`get-urbanXDetails`],
    queryFn: () => GetUrbanXDetails(getAuthToken),
  })

  const onSaveChanges = () => {
    if (urbanXDetailsForm) {
      updateUrbanXDetails({
        Address: {
          PostalCode: urbanXDetailsForm.address?.postalCode,
          State: urbanXDetailsForm.address?.state,
          StreetAddress: urbanXDetailsForm.address?.streetAddress,
          Suburb: urbanXDetailsForm.address?.suburb,
        },
        CompanyDetails: {
          Abn: urbanXDetailsForm.companyDetails?.abn,
          Acn: urbanXDetailsForm.companyDetails?.acn,
          Licences: urbanXDetailsForm.companyDetails.licences,
          //ExpiryDate: urbanXDetailsForm.companyDetails?.expiryDate,
          LicenseeName: urbanXDetailsForm.companyDetails?.licenseeName,
          //licenceNumber: urbanXDetailsForm.companyDetails?.licenceNumber,
        },
        ContractsEmail: urbanXDetailsForm.contractsEmail,
        ListingsEmail: urbanXDetailsForm.listingsEmail,
        ListingsPhoneNumber: urbanXDetailsForm.listingsPhoneNumber,
        TradingName: urbanXDetailsForm.tradingName,
        getAuthToken,
      })
    }
  }

  const { mutate: updateUrbanXDetails } = useMutation({
    mutationFn: UpdateUrbanXDetails,
    onSuccess: () => {
      queryClient.setQueryData([`get-urbanXDetails`], urbanXDetailsForm)
      updateToastState({
        color: 'success',
        message: `${MenuTitles.LICENSEE_DETAILS} updated successfully`,
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: `Error updating ${MenuTitles.LICENSEE_DETAILS}`,
        open: true,
      })
    },
  })

  useEffect(() => {
    if (urbanXDetails) {
      const licence = urbanXDetails.companyDetails.licences
      if (licence && licence.length > 0) {
        setLicenceDetails(licence[0])
      }
      setUrbanXDetailsForm(urbanXDetails)
    }
  }, [urbanXDetails])

  const onCompanyDetailsChange = (
    value: string | LicenceDetails[],
    prop: CompanyDetailsKeys
  ) => {
    if (urbanXDetailsForm && urbanXDetails) {
      setUrbanXDetailsForm({
        ...urbanXDetailsForm,
        companyDetails: { ...urbanXDetailsForm.companyDetails, [prop]: value },
      })
    }
  }

  const onAddressDetailsChange = (value: string, prop: CompanyAddressKeys) => {
    if (urbanXDetailsForm && urbanXDetails) {
      setUrbanXDetailsForm({
        ...urbanXDetailsForm,
        address: { ...urbanXDetailsForm.address, [prop]: value },
      })
    }
  }

  const onAgencyDetailsChange = (value: string, prop: UrbanXDetailsKeys) => {
    if (urbanXDetailsForm && urbanXDetails) {
      setUrbanXDetailsForm({
        ...urbanXDetailsForm,
        [prop]: value,
      })
    }
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={MenuTitles.LICENSEE_DETAILS}
      isStaff={isStaff}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.LICENSEE_DETAILS}
        isStaff={isStaff}
      >
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween centered extraClasses="borderBottomContainer">
            <AgxLabel large>{MenuTitles.LICENSEE_DETAILS}</AgxLabel>
            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={() => onSaveChanges()}
            />
          </AgxRow>

          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Company details</AgxLabel>
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="tradingName"
                label="Trading Name"
                stretch
                defaultValue={urbanXDetails?.tradingName || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAgencyDetailsChange(value, 'tradingName')
                }
                noOptionalLabel
              />
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="licenseeName"
                label="Licensee name"
                defaultValue={urbanXDetails?.companyDetails?.licenseeName || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onCompanyDetailsChange(value, 'licenseeName')
                }
                noOptionalLabel
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="abn"
                label="ABN"
                abn={true}
                defaultValue={urbanXDetails?.companyDetails?.abn || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onCompanyDetailsChange(value, 'abn')
                }
                noOptionalLabel
              />

              <AgxTextInput
                id="acn"
                label="ACN"
                acn={true}
                defaultValue={urbanXDetails?.companyDetails?.acn || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onCompanyDetailsChange(value, 'acn')
                }
                noOptionalLabel
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="licenceNumber"
                label="Licence Number"
                defaultValue={licenceDetails.licenceNumber}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) => {
                  const updatedLicence: LicenceDetails = {
                    expiryDate: licenceDetails.expiryDate,
                    licenceNumber: value,
                  }
                  setLicenceDetails(updatedLicence)
                  onCompanyDetailsChange([updatedLicence], 'licences')
                }}
                noOptionalLabel
              />

              <AgxDatePicker
                id="dtExpirtDate"
                date
                label="Expiry Date"
                noOptionalLabel
                stretch
                defaultValue={licenceDetails.expiryDate}
                onValueChanged={({ value }: { value: string }) => {
                  const updatedLicence: LicenceDetails = {
                    expiryDate: value,
                    licenceNumber: licenceDetails.licenceNumber,
                  }
                  setLicenceDetails(updatedLicence)
                  onCompanyDetailsChange([updatedLicence], 'licences')
                }}
              />
            </AgxRow>
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Contact Details</AgxLabel>
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="listingsEmail"
                label="Listings Email"
                defaultValue={urbanXDetails?.listingsEmail || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAgencyDetailsChange(value, 'listingsEmail')
                }
                noOptionalLabel
              />

              <AgxTextInput
                id="contractEmail"
                label="Contract Email"
                defaultValue={urbanXDetails?.contractsEmail || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAgencyDetailsChange(value, 'contractsEmail')
                }
                noOptionalLabel
              />
            </AgxRow>
            <AgxRow>
              <AgxPhoneNumberInput
                id="phoneNumber"
                label="Listings Phone Number"
                defaultValue={urbanXDetails?.listingsPhoneNumber || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAgencyDetailsChange(value, 'listingsPhoneNumber')
                }
                noOptionalLabel
              />
            </AgxRow>
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>UrbanX Address</AgxLabel>
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="streetAddress"
                label="Street Address"
                defaultValue={urbanXDetails?.address?.streetAddress || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAddressDetailsChange(value, 'streetAddress')
                }
                noOptionalLabel
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="suburb"
                label="Suburb"
                defaultValue={urbanXDetails?.address?.suburb || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAddressDetailsChange(value, 'suburb')
                }
                noOptionalLabel
              />

              <AgxSelect
                id="state"
                label="State"
                hideOptionalLabel
                defaultValue={{
                  label: urbanXDetailsForm?.address?.state || 'QLD',
                  value: urbanXDetailsForm?.address?.state || 'QLD',
                }}
                onValueChanged={({ value }: { value: string }) => {
                  onAddressDetailsChange(value, 'state')
                }}
                options={[
                  { value: 'QLD', label: 'QLD' },
                  { value: 'NSW', label: 'NSW' },
                ]}
              />

              <AgxTextInput
                id="postcode"
                label="Postcode"
                defaultValue={urbanXDetails?.address?.postalCode || ''}
                stretch
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  onAddressDetailsChange(value, 'postalCode')
                }
                noOptionalLabel
              />
            </AgxRow>
          </AgxColumn>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default StaffLicense
