import React, { useContext } from 'react'
import {
  AgxBodyText,
  AgxColumn,
  AgxDivider,
  AgxLabel,
  AgxRow,
  cleanFullAddress,
  DocumentTypes,
  DocumentTypesMap,
  Images,
} from '@urbanx/agx-ui-components'
import { ContentLayout, PageLayout } from 'layout'
import { UserContext } from 'contexts'
import { MenuTitles } from 'constants/menu'
import useSelectedAgency from 'hooks/useSelectedAgency'
import useSelectedCampaign from 'hooks/useSelectedCampaign'
import useSelectedContract from 'hooks/useSelectedContract'
import { formatFileSize } from 'utils/formatFileSize'
import { useAzureAuth } from 'hooks'
import { openFileInNewTab } from 'utils/openFileInNewTab'
import './ContractFiles.scss'

const ContractFiles = () => {
  const user = useContext(UserContext)

  const [, getAuthToken] = useAzureAuth()
  const { agency } = useSelectedAgency()
  const { campaign } = useSelectedCampaign()
  const { contract } = useSelectedContract()

  const getDocumentTypeLabel = (documentType: string) =>
    DocumentTypesMap[documentType as DocumentTypes]

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={agency?.name || ''}
      currentPageTitle={`Campaigns / ${cleanFullAddress(campaign?.address)}`}
      isCampaignsSection={true}
    >
      <ContentLayout
        hasSideMenu={true}
        activeMenu={MenuTitles.FILES}
        isContracts={true}
      >
        <AgxColumn extraClasses="panelContainer files-panel">
          <AgxRow extraClasses="panelTitleContainer">
            <AgxLabel medium extraClasses="left-header">
              Contract Files
            </AgxLabel>
            <AgxLabel medium extraClasses="right-header">
              Date Uploaded
            </AgxLabel>
          </AgxRow>
          {!!contract &&
            contract.documents.length > 0 &&
            contract.documents.map((document, innerIndex) => (
              <React.Fragment key={document.documentId}>
                <React.Fragment>
                  {innerIndex > 0 && <AgxDivider />}
                  <AgxLabel medium>
                    {getDocumentTypeLabel(document.documentType)}
                  </AgxLabel>
                </React.Fragment>
                <AgxRow key={document.documentId}>
                  <AgxColumn extraClasses="file-document">
                    <div
                      className="file-document-container"
                      onClick={() => openFileInNewTab(getAuthToken, document)}
                    >
                      <Images.FileTextOutline className="file-document-leftIcon" />
                      <AgxBodyText medium extraClasses="file-document-body">
                        <span className="file-document-name">
                          {document.fileName}
                        </span>
                        <span className="file-document-size">
                          {formatFileSize(document.fileSizeInBytes)}
                        </span>
                      </AgxBodyText>
                      <Images.Download className="file-document-download" />
                    </div>
                  </AgxColumn>
                  <AgxColumn extraClasses="file-document-date">
                    <AgxBodyText>
                      <span>
                        {document.dateUploaded
                          ? new Date(document.dateUploaded).toLocaleString(
                              'en-US'
                            )
                          : ''}
                      </span>
                    </AgxBodyText>
                  </AgxColumn>
                </AgxRow>
              </React.Fragment>
            ))}
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default ContractFiles
