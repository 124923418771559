import { FeatureToggles } from '@urbanx/agx-ui-components'
import CampaignsService from 'services/campaignsService'

const GetFeatureToggles = async (
  campaignId?: string,
  getAuthToken?: () => Promise<string | void>
): Promise<FeatureToggles | undefined> => {
  if (!campaignId || !getAuthToken) return

  const token = await getAuthToken()

  if (!token) return

  const service = new CampaignsService(token)
  if (!service) return

  const { data: featureToggles } = await service.campaignsService.get(
    'StaffGetFeatureToggles',
    { campaignId }
  )

  return featureToggles || {}
}

export default GetFeatureToggles
